import { ReactElement } from 'react';

import { RenderingField, RenderingQuestion } from '@breathelife/questionnaire-engine';
import { FieldTypes } from '@breathelife/types';

import { isUnansweredField } from '../../Helpers';
import { BaseFieldProps } from '../FieldGenerator/FieldGenerator';
import { Field } from './Field';
import { Label, QuestionContainer } from './Styles';
import { ReactHtmlParser } from '@breathelife/ui-components';

export type PdfQuestionProps = BaseFieldProps & {
  avoidBreaks: boolean;
};

function isQuestionHidden(question: RenderingQuestion): boolean {
  if (!question.visible) {
    return true;
  }

  if (question.showInPdfWithoutFields) {
    return false;
  }

  const fieldsDoNotExistOrAreAllUnanswered =
    !question.fields.length ||
    question.fields.every((field) => isUnansweredField(field) && field.type !== FieldTypes.information);
  if (fieldsDoNotExistOrAreAllUnanswered) {
    return true;
  }

  return false;
}

export function PdfQuestion(props: PdfQuestionProps): ReactElement | null {
  const { avoidBreaks, currency, locale, question } = props;
  if (isQuestionHidden(question)) return null;
  return (
    <QuestionContainer avoidBreakInside={avoidBreaks}>
      {question.title && (
        <Label>
          <ReactHtmlParser html={question.title} />
        </Label>
      )}
      {question.text && (
        <Label data-testid={`question-text-${question.id}`}>
          <ReactHtmlParser html={question.text} />
        </Label>
      )}
      {question.fields.map((field: RenderingField) => (
        <Field key={field.id} currency={currency} field={field} locale={locale} />
      ))}
    </QuestionContainer>
  );
}
