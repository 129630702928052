import { Box } from '@breathelife/mui';
import _ from 'lodash';
import { ReactNode, ReactElement, Fragment } from 'react';

import { OptionWidth, RenderingFieldOption } from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';
import {
  CheckboxCard,
  CheckboxCardContainer,
  FieldProps,
  formatRequiredFieldTitle,
  Icon,
  InfoSupplement,
  Label,
  ReactHtmlParser,
  WarningText,
} from '@breathelife/ui-components';

import { translate } from '../../../Localization/Localizer';
import { StyleVariant } from '../../FieldGenerator/FieldGenerator';
import { CheckboxFieldMUI } from './Checkbox';
import { StyledFormControl, TextWithSuperscriptHighlight, Title } from './Styles';

type CheckboxFieldProps = Omit<FieldProps, 'onAnswerChange'> & {
  id: string;
  options: RenderingFieldOption[];
  required?: boolean;
  value?: string[];
  optionWidth?: OptionWidth;
  onAnswerChange?: (answer: string[]) => void;
  onAnswerComplete?: (field: string, answer?: boolean, previousAnswer?: boolean) => void;
  styleVariant: StyleVariant;
  iconMap?: Record<string, string>;
  onInfoIconClick?: () => void;
  locale?: Language;
  boldedBorder?: boolean;
};

function computeAnswerAfterChange(originalAnswer: string[], optionId: string, answer: boolean): string[] {
  if (answer) {
    return _.union(originalAnswer, [optionId]);
  }
  return _.remove(originalAnswer, (e) => e !== optionId);
}

function isOptionChecked(groupAnswers: unknown, optionId: string): boolean {
  return Array.isArray(groupAnswers) && groupAnswers.includes(optionId);
}

export function CheckboxGroup(props: CheckboxFieldProps): ReactElement {
  const {
    disabled,
    iconMap,
    id,
    onInfoIconClick,
    label,
    locale,
    name,
    onAnswerChange,
    onAnswerComplete,
    optionalText,
    options,
    optionWidth,
    required,
    styleVariant,
    subtitle,
    title,
    validationError,
    value,
    boldedBorder,
  } = props;
  const showError = !!validationError;
  const labelValue = formatRequiredFieldTitle(required, title, label, optionalText);
  const labelId = `${props.id}-label`;

  if (styleVariant === StyleVariant.consumer) {
    const validationMessageId = `${id}-error`;
    const optionCards: ReactNode[] = [];
    const infoSupplements: ReactNode[] = [];

    options.forEach((option: RenderingFieldOption) => {
      const isChecked = isOptionChecked(value, option.id);
      optionCards.push(
        <CheckboxCard
          key={option.id}
          optionId={option.id}
          ariaLabel={option.text}
          groupName={id}
          checked={isChecked}
          onChange={(optionId: string, checked: boolean) =>
            onAnswerChange && onAnswerChange(computeAnswerAfterChange(value as string[], optionId, checked))
          }
          suffix={option.iconName ? <Icon name={option.iconName} size='56px' /> : undefined}
          showErrorBorder={showError}
          disabled={disabled}
          marginless
        >
          {option.title && (
            <Title>
              <ReactHtmlParser html={option.title} />
            </Title>
          )}
          <TextWithSuperscriptHighlight selected={isChecked}>
            <ReactHtmlParser html={option.text} />
          </TextWithSuperscriptHighlight>
        </CheckboxCard>,
      );

      if (option.info) {
        const { title, text, iconNumber, modalOptions, image } = option.info;

        const imageSrc = image && iconMap?.[image.name];
        const imageInfo = imageSrc
          ? {
              src: imageSrc,
              alt: image?.alt,
            }
          : undefined;

        infoSupplements.push(
          <Box mt={1.5}>
            <InfoSupplement
              text={text}
              title={title}
              image={imageInfo}
              modalOptions={modalOptions}
              iconNumber={iconNumber}
              onClick={onInfoIconClick}
            />
          </Box>,
        );
      }
    });

    return (
      <Fragment>
        {labelValue && (
          <Box mb={1}>
            <Label htmlFor={id}>
              <ReactHtmlParser html={labelValue} />
            </Label>
          </Box>
        )}
        {subtitle && (
          <Box mb={1}>
            <Label htmlFor={id} grey={80}>
              <ReactHtmlParser html={subtitle} />
            </Label>
          </Box>
        )}
        <CheckboxCardContainer
          id={id}
          aria-required={required}
          aria-errormessage={validationMessageId}
          optionWidth={optionWidth}
        >
          {optionCards}
        </CheckboxCardContainer>
        {infoSupplements}
        <Box component={WarningText} mt={2} role='alert' id={validationMessageId}>
          {showError && (validationError?.message || translate('validation.radioCheckbox', { locale }))}
        </Box>
      </Fragment>
    );
  }

  return (
    <StyledFormControl
      variant='standard'
      component={'fieldset'}
      required={required}
      aria-required={required}
      error={showError}
    >
      {labelValue && (
        <Box mb={1} whiteSpace={optionWidth === 'full' ? 'normal' : 'noWrap'}>
          <Label id={labelId} htmlFor={id}>
            <ReactHtmlParser html={labelValue} />
          </Label>
        </Box>
      )}
      {subtitle && (
        <Box mb={1} whiteSpace={optionWidth === 'full' ? 'normal' : 'noWrap'}>
          <Label id={labelId} htmlFor={id} grey={80}>
            <ReactHtmlParser html={subtitle} />
          </Label>
        </Box>
      )}
      {options.map((option: RenderingFieldOption) => {
        if (!option.visible) return null;

        return (
          <CheckboxFieldMUI
            id={`${id}_${option.id}`}
            key={option.id}
            name={option.id}
            label={option.text}
            describedBy={labelId}
            required={required}
            disabled={disabled}
            showError={showError}
            value={isOptionChecked(value as string[], option.id)}
            styleVariant={styleVariant}
            onAnswerChange={(answer: any) => {
              onAnswerChange && onAnswerChange(computeAnswerAfterChange(value as string[], option.id, answer));
            }}
            onAnswerComplete={(optionId: string, answer?: boolean, previousAnswer?: boolean) => {
              onAnswerComplete && onAnswerComplete(name, answer, previousAnswer);
            }}
            boldedBorder={boldedBorder}
          />
        );
      })}
      {showError && (
        <WarningText>{validationError?.message || translate('validation.radioCheckbox', { locale })}</WarningText>
      )}
    </StyledFormControl>
  );
}
