import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';

import { Bold, CheckedIcon, UncheckedIcon } from './Styles';
import { ReactHtmlParser } from '@breathelife/ui-components';

export type CheckboxData = { title?: string; label: string; checked: boolean };

type CheckboxProps = {
  checkbox: CheckboxData;
  iconColor?: 'primary' | 'inherit';
};

export function Checkbox(props: CheckboxProps): ReactElement {
  const { checkbox, iconColor = 'inherit' } = props;
  return (
    <Box ml={1.5}>
      {checkbox.checked ? <CheckedIcon color='primary' /> : <UncheckedIcon color={iconColor} />}
      {checkbox.title && (
        <Bold>
          <ReactHtmlParser html={checkbox.title} />:
        </Bold>
      )}{' '}
      <ReactHtmlParser html={checkbox.label} />
    </Box>
  );
}
