import { memo, ReactElement, useCallback, useMemo } from 'react';

import {
  getRepeatedQuestionIndex,
  OnAnswerChange,
  RenderingField,
  RenderingRepeatedQuestion,
} from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';

import { StyleVariant } from '../../../FieldGenerator/FieldGenerator';
import { QuestionContent } from '../QuestionContent';
import { Container } from '../Styles';
import { RepeatableQuestionContent } from './RepeatableQuestionContent';

type RepeatableQuestionProps = {
  question: RenderingRepeatedQuestion;
  onAnswerChange: OnAnswerChange;
  onAnswerComplete: (fieldId: string, answer: any, previousAnswer: any) => void;
  styleVariant: StyleVariant;
  locale?: Language;
  iconMap?: Record<string, string>;
  focusedIncompleteFieldId?: string;
};

export const RepeatableQuestion = memo(InnerRepeatableQuestion);

export function InnerRepeatableQuestion(props: RepeatableQuestionProps): ReactElement {
  const { question, onAnswerChange, onAnswerComplete, styleVariant, locale, iconMap, focusedIncompleteFieldId } = props;
  const repeatedQuestionIndex: number = getRepeatedQuestionIndex(question);

  const addRepeatedQuestion = useCallback(() => {
    onAnswerChange([
      {
        tag: 'blueprintId',
        blueprintId: question.blueprintId,
        nodeId: question.nodeId,
        value: {},
        effects: question.effects,
        blueprintIdScope: {
          ...question.metadata.repeatedInstanceIdentifierContext.byBlueprintId,
          [question.blueprintId]: repeatedQuestionIndex + 1,
        },
        nodeIdScope: {
          ...question.metadata.repeatedInstanceIdentifierContext.byNodeId,
          [question.nodeId]: repeatedQuestionIndex + 1,
        },
      },
    ]);
  }, [
    onAnswerChange,
    question.blueprintId,
    question.nodeId,
    question.effects,
    question.metadata.repeatedInstanceIdentifierContext.byBlueprintId,
    question.metadata.repeatedInstanceIdentifierContext.byNodeId,
    repeatedQuestionIndex,
  ]);

  const deleteRepeatedQuestion = useCallback(() => {
    onAnswerChange([
      {
        tag: 'blueprintId',
        blueprintId: question.blueprintId,
        nodeId: question.nodeId,
        value: undefined,
        effects: question.effects,
        blueprintIdScope: question.metadata.repeatedInstanceIdentifierContext.byBlueprintId,
        nodeIdScope: question.metadata.repeatedInstanceIdentifierContext.byNodeId,
      },
    ]);
  }, [
    onAnswerChange,
    question.blueprintId,
    question.effects,
    question.metadata.repeatedInstanceIdentifierContext.byBlueprintId,
    question.metadata.repeatedInstanceIdentifierContext.byNodeId,
    question.nodeId,
  ]);

  const renderContent = useMemo(() => {
    return renderRepeatableQuestionContent(onAnswerChange, styleVariant, locale, iconMap, focusedIncompleteFieldId);
  }, [iconMap, locale, onAnswerChange, styleVariant]);

  return (
    <Container
      styleVariant={styleVariant}
      key={question.surrogateId ?? question.id}
      data-testid={'repeatable_question'}
    >
      <RepeatableQuestionContent
        question={question}
        onQuestionAdd={addRepeatedQuestion}
        onQuestionDelete={deleteRepeatedQuestion}
        onAnswerComplete={onAnswerComplete}
        renderContent={renderContent}
        styleVariant={styleVariant}
      />
    </Container>
  );
}

function renderRepeatableQuestionContent(
  onAnswerChange: OnAnswerChange,
  styleVariant: StyleVariant,
  locale?: Language,
  iconMap?: Record<string, string>,
  focusedIncompleteFieldId?: string,
) {
  return (
    fields: RenderingField[],
    onAnswerComplete: (fieldId: string, answer: any, previousAnswer: any, repeatedQuestionIndex?: number) => void,
  ) => (
    <QuestionContent
      fields={fields}
      onAnswerChange={onAnswerChange}
      onAnswerComplete={onAnswerComplete}
      styleVariant={styleVariant}
      locale={locale}
      iconMap={iconMap}
      focusedIncompleteFieldId={focusedIncompleteFieldId}
    />
  );
}
